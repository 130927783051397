.main {
  box-sizing: border-box;
  display: block;
  padding: 0 1rem;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .main {
    width: 90%;
  }
}
