.logo {
  background-color: var(--twin-color);
  border-radius: 50%;
}

.burger {
  height: 150px;
  margin: 2rem 0;
  width: 150px;
}

.navbar {
  height: 50px;
  width: 50px;
}
