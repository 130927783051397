.card {
  align-items: center;
  background: var(--bg);
  border-bottom: 2px solid var(--twin-color);
  border-radius: 15px;
  box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  column-gap: 1.5rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 65% calc(35% - 1.5rem);
  justify-content: center;
  margin: 1rem;
  min-height: 100px;
  opacity: 0.9;
  padding: 1rem;
  position: relative;
  row-gap: 1rem;
  transition: all 0.25s ease-in-out;
  width: 350px;
  -moz-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: var(--box-shadow-hover);
    opacity: 1;
    transform: scale(1.02, 1.02);
    transition: transform 0.1s ease;
  }

  &::before {
    border-radius: 15px;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subtitle {
      font-style: italic;
      margin: 1rem 0;
    }
  }

  .link {
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1;

    .linkIcon {
      background-color: var(--twin-color);
      border-radius: 50%;
      cursor: pointer;
      margin-bottom: 1rem;
      padding: 1rem;

      img {
        width: 3rem;
      }
    }
  }
}

.download {
  background-color: var(--twin-color);
  border-bottom: 2px solid var(--text);

  .link {
    .linkIcon {
      margin: 0;
      margin: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .card {
    width: 40%;
    zoom: 0.9;
  }

  .subtitle {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    zoom: 0.75;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}
