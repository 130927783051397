.brand {
  display: flex;
  justify-content: center;
}

.up {
  position: fixed;
  right: 1rem;
  top: 90vh;
  z-index: 3;
}
