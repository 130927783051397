.buyWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .buy {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .buyWrap {
    align-items: center;
    display: grid;
    grid-template-columns: 60% 35%;
    justify-content: space-between;

    .buy {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      width: 100%;
    }
  }
}
