.menuWrap {
  left: 0;
  position: absolute;

  .wrap {
    background: var(--bg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    height: 100vh;
    list-style-type: none;
    margin: 0;
    opacity: 1;
    padding: 10px;
    width: 70vw;

    .content {
      font-size: 1rem;
      margin-bottom: 1rem;

      .menu {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        position: relative;
        text-transform: uppercase;

        > svg {
          align-items: center;
          display: flex;
          font-size: 1.5rem;
          justify-content: flex-start;
          margin-right: 0.5rem;
        }

        > span {
          align-items: center;
          color: var(--text);
          display: flex;
          font-size: 0.8rem;
          justify-content: flex-start;
        }
      }
    }

    .active {
      color: var(--twin-color);
      opacity: 1;

      &::after {
        margin-top: 1rem;
        transform: scaleX(1);
        visibility: visible;
      }

      > svg {
        align-items: center;
        color: var(--twin-color);
        display: flex;
        font-size: 1.5rem;
        justify-content: flex-start;
      }
    }
  }
}

@media (min-width: 769px) {
  .menuWrap {
    display: none;
  }
}
