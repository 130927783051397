.contactWrap {
  width: 100%;

  .contact {
    background: var(--bg);
    border-bottom: 2px solid var(--twin-color);
    border-radius: 20px;
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 1.5rem;
    position: relative;
    -moz-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);

    .title {
      margin: 1rem 0;
    }

    .letsTalk {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      .link {
        align-items: center;
        color: var(--text);
        display: flex;
        flex-direction: column;
        text-decoration: none;
        zoom: 0.65;

        .btn {
          background-color: transparent;
          border-radius: 30%;
          display: inline-block;
          margin: 0 1vmin;
          opacity: 0.9;
          overflow: hidden;
          position: relative;
          text-align: center;

          .facebook,
          .mail {
            font-size: 50px;
            padding: 1rem;
            transform: scale(0.8);
            transition: color 1s ease !important;
            vertical-align: middle;
          }

          .facebook {
            color: #0e76a8;
          }

          .mail {
            color: var(--twin-color);
          }

          &::before {
            background-color: #3b5998;
            content: '';
            height: 120%;
            left: -110%;
            position: absolute;
            top: 90%;
            transform: rotate(45deg);
            width: 120%;
          }

          &:focus::before,
          &:hover::before {
            left: -10%;
            top: -10%;
          }

          &:focus .facebook,
          &:hover .facebook,
          &:focus .mail,
          &:hover .mail {
            color: var(--bg);
            transform: scale(1);
            transition: color 1s ease !important;
          }
        }

        .btn,
        .btn::before,
        .btn .facebook,
        .btn .mail {
          transition: all 0.35s, color 2s ease, background-color 2s ease;
          transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
        }

        .mailBtn {
          &::before {
            background-color: var(--twin-color);
          }
        }
      }
    }

    .form {
      align-items: center;
      display: flex;
      flex-direction: column;

      .input {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;

        > span {
          align-items: flex-end;
          display: flex;
          justify-content: center;
          margin-bottom: 2.5rem;
          width: 100%;

          > span {
            width: 95%;
          }
        }
      }

      .textarea {
        margin-bottom: 2rem;
        width: 95%;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        > span {
          margin-bottom: 1rem;
          width: 100%;

          > button {
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .contactWrap {
    display: grid;
    grid-template-columns: 35% 65%;
    width: 100%;

    .contact {
      display: grid;
      grid-template-areas:
        'title title'
        'letsTalk form';
      grid-template-rows: 25% 75%;

      .title {
        grid-area: title;
        margin: 0;
      }

      .letsTalk {
        align-items: center;
        display: flex;
        flex-direction: column;
        grid-area: letsTalk;
        justify-content: flex-start;
        padding: 1rem 0;
        width: 100%;

        .link {
          flex-direction: row;
          margin-bottom: 2rem;
          width: 100%;
        }
      }

      .form {
        display: grid;
        grid-area: form;
        grid-template-rows: auto auto;
        margin: 0 2rem;
        width: calc(100% - 4rem);

        .input {
          column-gap: 1rem;
          display: grid;
          grid-template-columns: 35% calc(65% - 1rem);
          justify-content: space-between;
          width: 100%;

          > span {
            margin-bottom: 0;
            width: 100%;
          }
        }

        .input,
        .textarea {
          font-size: 1rem;
          margin-bottom: 0;
          width: 100%;

          textarea {
            width: 97%;
          }
        }

        .buttons {
          flex-direction: row;
          justify-content: flex-end;
          zoom: 0.85;

          > span {
            margin-left: 1rem;
            width: auto;
          }
        }
      }
    }
  }
}
