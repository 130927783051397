.burger {
  cursor: pointer;
  transition: all 0.2s ease-out;

  .line {
    backface-visibility: hidden;
    background: var(--text);
    height: 3px;
    margin: 4px;
    transition: all 0.3s;
    width: 50%;
  }
}

.on {
  .top {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .middle {
    opacity: 0;
  }

  .bottom {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}
