.button {
  align-items: center;
  background-color: var(--twin-color);
  border-radius: 5px;
  border: var(--button-primary-border);
  color: var(--text);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: space-around;
  margin-right: 0.25em;
  margin: 0;
  opacity: 0.85;
  overflow: visible;
  padding: 0.25rem;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
  -moz-border-radius: 5px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -moz-user-select: none;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-border-radius: 5px;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-user-select: none;

  &:hover {
    opacity: 1;

    .icon {
      transform: scale(1.15);
      transition: transform 0.2s ease;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.2em var(--twin-color);
    outline-offset: 0;
    outline: 0 none;
    -moz-box-shadow: 0 0 0 0.2em var(--twin-color);
    -webkit-box-shadow: 0 0 0 0.2em var(--twin-color);
  }

  &:active {
    background-color: var(--twin-color-dark);
    border-color: var(--twin-color-dark);
    color: var(--text);
  }

  > span {
    padding: 0.35rem;
  }

  .text {
    font-size: large;
    line-height: normal;
    text-transform: capitalize;
  }

  .icon {
    align-items: center;
    display: flex;
    font-size: x-large;
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.loading {
  .icon {
    animation: spin infinite 3s linear;
  }
}

.reset {
  background: var(--twin-color-dark);

  &:hover {
    .icon {
      @media (prefers-reduced-motion: no-preference) {
        animation: spin infinite 1s linear;
      }
    }
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
