@charset "UTF-8";

:root {
  --a1: #d71967;
  --a2: #bb1e61;
  --adjectives: #e36a25;
  --animals: #36ae4a;
  --b1: #991d55;
  --b2: #82184b;
  --bg: #f9fafb;
  --bg2: #e6ecf0;
  --box-shadow-hover: 0 10px 20px rgba(0, 0, 0, 0.21), 0 6px 6px rgba(0, 0, 0, 0.25);
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.4);
  --c1: #660e3c;
  --c2: #4a0c2e;
  --clothes: #2c429a;
  --countries: #8e4599;
  --error: #bb1e1e;
  --food: #06a9b1;
  --info: #06a9b1;
  --pasapalabra: #4073de;
  --scrollBar-thumb-hover: #555;
  --scrollBar-thumb: #888;
  --scrollBar-track: #f1f1f1;
  --success: #49d160;
  --text: #282c34;
  --twin-color-dark: rgba(236, 204, 92, 0.4);
  --twin-color-light: #53b2da;
  --twin-color: #eccc5c;
  --verbs: #edba1c;
  --white: #ffffff;
}

.dark-mode {
  --bg: #282c34;
  --bg2: #15202b;
  --box-shadow-hover: 0 10px 20px rgba(0, 0, 0, 0.29), 0 6px 6px rgba(0, 0, 0, 0.33);
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.6);
  --placeholder: #ffffffb3;
  --text: #f9fafb;
  --twin-color-dark: rgba(83, 178, 218, 0.4);
  --twin-color-light: #eccc5c;
  --twin-color: #53b2da;
}

@font-face {
  font-family: 'GothamLight';
  src: local('GothamLight'), url(./assets/fonts/Gotham-Light.otf) format('truetype');
}

body {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background: linear-gradient(135deg, var(--bg2) -10%, var(--bg) 100%);
  font-family: -apple-system, GothamLight, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  user-select: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.fadeInLeft {
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
  -webkit-animation-name: fadeInLeftBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    -webkit-transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
    -webkit-transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}
