.author {
  align-items: center;
  background: var(--bg);
  border-bottom: 2px solid var(--twin-color-light);
  border-radius: 20px;
  box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  -moz-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.2);

  .img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
  }

  .title {
    margin: 0;
    padding-bottom: 0.5rem;
    padding: 1.5rem;
    text-align: center;
  }

  .text {
    padding: 1.5rem;
    text-align: justify;
    text-justify: inter-word;
  }
}

@media screen and (min-width: 1025px) {
  .authorWrap {
    display: grid;
    grid-template-columns: 70% 30%;

    .author {
      flex-direction: row;
      padding: 1rem;

      .img {
        border-radius: 20px;
        height: 250px;
        width: 250px;
      }

      .description {
        font-size: 1rem;

        .title {
          text-align: left;
        }
      }
    }
  }
}
