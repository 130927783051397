.menu {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: 0.7;
  position: relative;
  text-transform: uppercase;

  &::after {
    background-color: var(--twin-color);
    content: ' ';
    height: 2px;
    left: 0;
    margin-top: 1rem;
    position: absolute;
    transform: scaleX(0);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    visibility: hidden;
    width: 100%;
    -moz-transform: scaleX(0);
    -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transform: scaleX(0);
    -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transform: scaleX(0);
    -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform: scaleX(0);
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &:hover {
    opacity: 1;
    text-decoration: none;

    &::after {
      transform: scaleX(1);
      visibility: visible;
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -o-transform: scaleX(1);
      -webkit-transform: scaleX(1);
    }
  }

  > svg {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    justify-content: flex-start;
    margin-right: 0.5rem;
  }

  > span {
    align-items: center;
    display: flex;
    font-size: 0.8rem;
    justify-content: flex-start;
  }
}

.active {
  color: var(--twin-color);
  opacity: 1;

  > svg {
    align-items: center;
    color: var(--twin-color);
    display: flex;
    font-size: 1.5rem;
    justify-content: flex-start;
  }

  &::after {
    margin-top: 1rem;
    transform: scaleX(1);
    visibility: visible;
  }
}
