.section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 3rem;
  padding: 3rem 0 3rem 0;
  position: relative;
  width: 100%;

  .title {
    color: var(--text);
    display: block;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 5px;
    margin-bottom: 15px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .subtitle {
    color: var(--text);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 5px;
    line-height: 1.8;
    margin-bottom: 1em;
    position: relative;
    text-transform: uppercase;

    span {
      color: var(--twin-color);
    }
  }
}

.author {
  background: linear-gradient(30deg, var(--twin-color-light), var(--twin-color-light) 30%, transparent 30%, transparent);
  border-radius: 20px;
  margin-bottom: 5rem;
  padding: 1rem;
  width: calc(100% - 2rem);

  @media screen and (min-width: 769px) {
    background: linear-gradient(50deg, transparent, transparent 55%, var(--twin-color-light) 55%, var(--twin-color-light));
  }
}

.buy {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.contact {
  background: linear-gradient(120deg, var(--twin-color), var(--twin-color) 40%, var(--bg) 40%, var(--bg));
  border-radius: 20px;
  padding: 1rem;
  width: calc(100% - 2rem);
}

@media screen and (max-width: 768px) {
  .subtitle {
    margin-bottom: 3em;
  }
}
